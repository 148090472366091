import React, { useEffect, useContext } from 'react'
import CookieConsent from 'react-cookie-consent'

import GlobalContext, { themeConfigDefault } from '../../context/GlobalContext'

const PageWrapper = ({ children, themeConfig = null }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    gContext.changeTheme({ ...themeConfigDefault, ...themeConfig })
  }, [])

  return (
    <>
      {children}
      <CookieConsent
        location='bottom'
        buttonText='Sure!'
        cookieName='basicOmniCookie'
        style={{ background: '#2B373B' }}
        buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
        expires={150}>
        This website uses cookies to enhance the user experience.{' '}
        <span style={{ fontSize: '10px' }}>We do not collect any personal information.</span>
      </CookieConsent>
    </>
  )
}

export default PageWrapper
